/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          name
          title
          description
          author
          background_color
        }
      }
    }
  `)

  return (
    <div
      style={{
        backgroundColor: data.site.siteMetadata.background_color,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr 2.5rem",
        height: "100vh",
        border: ".5rem solid #86BBD8",
      }}
    >
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: "80%",
          alignContent: "center",
          display: "grid",
        }}
      >
        <main
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridTemplateRows: "1fr",
            gridColumnGap: "5rem",
          }}
        >
          {children}
        </main>
      </div>
      <footer
        style={{ margin: "0 1rem", fontSize: ".6rem", lineHeight: "1rem" }}
      >
        All right reserved, {data.site.siteMetadata.name} ©{" "}
        {new Date().getFullYear()}, Built in Paris, FR with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>,<br /> hosted by{" "}
        <a href="https://pages.cloudflare.com/">Cloudflare Pages</a> and protected by{" "}
        <a href="https://www.cloudflare.com">Cloudflare</a>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
