/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            name
            jobTitle
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.name} | ${site.siteMetadata.jobTitle
        }`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "Person",
          name: "Julien Madiot",
          url: "https://julien-madiot.fr",
          image: "https://julien-madiot.fr/icons/icon-192x192.png",
          sameAs: [
            "https://www.linkedin.com/in/julienmadiot/",
            "https://github.com/Madi-Ji",
          ],
          jobTitle: site.siteMetadata.jobTitle,
          worksFor: {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Kong Inc.",
            "description": "Increase developer productivity, security, and performance at scale with the unified platform for API management, service mesh, and ingress controller",
            "alternateName": "KongHQ",
            "url": "https://konghq.com/",
            "logo": "https://konghq.com/favicon-180.png",
            "email": "peopleoperations@konghq.com",
            "telephone": "+1-415-754-9283",
            "sameAs": [
              "https://docs.konghq.com/",
              "https://www.facebook.com/konginc/",
              "https://www.instagram.com/thekonghq/",
              "https://www.youtube.com/KongInc",
              "https://twitter.com/thekonginc",
              "https://www.linkedin.com/company/konghq",
              "https://en.wikipedia.org/wiki/Kong_Inc."
            ]
          },
        })}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en-US`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
